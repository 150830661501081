<template>
  <v-flex class="d-flex align-items-center">
    <h3 class="custom-sub-header-blue-text mr-4">{{ searchTitle }}</h3>
    <v-text-field
    clearable
      ref="search"
      v-model.trim="listingSearch"
      :background-color="!isFocused ? 'grey lighten-3' : undefined"
      :placeholder="placeholder"
      autocomplete="off"
      hide-details
      outlined
      class="float-right mr-10"
      style="max-width: 450px; min-width: 400px"
      @focus="isFocused = true"
      @keydown.esc="onEsc"
      @keydown.enter="onEnter"
      @keydown.tab="onTab"
      v-on:click:clear="listingSearch = null,startSearch()"

    >
      <template #prepend-inner>
        <v-icon :color="!isFocused ? 'grey' : undefined" class="ml-1 mr-2"> mdi-magnify </v-icon>
      </template>
    </v-text-field>
  </v-flex>
</template>

<script>
import { SearchEventBus } from "@/core/lib/search.lib";
import { isEmpty } from "lodash";
import { mapGetters } from "vuex";
/* import { SET_SEARCH_MODEL } from "@/core/services/store/listing.module"; */
// products
// import { All_PRPDUCTS } from "@/core/lib/product.lib";

export default {
  data: () => ({
    isFocused: false,
    listingSearch: null,

    // product filter
  }),
  methods: {
    onEsc() {
      this.resetSearch();
    },
    onEnter() {
      console.log(this.searchTitle);
      this.startSearch();
    },
    onTab() {
      this.startSearch();
    },
    startSearch() {
      /*  this.$store.dispatch(SET_SEARCH_MODEL, this.listingSearch); */
      SearchEventBus.$emit("start-search", this.listingSearch);
    },
    resetSearch() {
      const _this = this;
      _this.$nextTick(() => {
        _this.listingSearch = null;
        _this.isFocused = false;
      });
    },
  },
  beforeDestroy() {
    document.onkeydown = null;
    this.resetSearch();
    /* this.$store.dispatch(SET_SEARCH_MODEL, null); */
    SearchEventBus.$off("search-template");
  },
  beforeCreate() {
    const _this = this;
    SearchEventBus.$on("search-template", (argument) => {
      if (!argument) {
        _this.resetSearch();
      }
    });
  },
  mounted() {
    const _this = this;
    _this.listingSearch = null;
    if (isEmpty(_this.$route.query) === false && _this.$route.query.search) {
      _this.listingSearch = _this.$route.query.search;
      _this.startSearch();
      _this.$refs.search.focus();
    }
  },
  computed: {
    ...mapGetters(["searchTitle"]),
    placeholder() {
      return this.isFocused
        ? "Start Typing then press enter"
        : "Search " + this.searchTitle + "...";
    },
    isSearching() {
      return this.listingSearch && this.listingSearch.length > 0;
    },
  },
};
</script>
