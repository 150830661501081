<template>
  <ul class="menu-nav">
    <template v-for="(data, index) in menuList">
      <template v-if="data.child">
        <li
          aria-haspopup="true"
          :key="index"
          data-menu-toggle="hover"
          class="menu-item menu-item-submenu"
          :class="{
            'menu-item-open menu-item-open-active': isRouteActive(data.name),
          }"
        >
          <a href="#" class="menu-link menu-toggle">
            <span class="menu-svg-icon mr-4">
              <!-- <inline-svg :src="$assetURL(data.icon)" /> -->
              <inline-svg fill="#fff" style="height: 25px; width: 25px" :src="data.icon" />
            </span>
            <span class="menu-text">{{ data.text }}</span>
            <i class="menu-arrow"></i>
          </a>
          <div class="menu-submenu">
            <span class="menu-arrow"></span>
            <ul class="menu-subnav">
              <router-link
                v-for="(submenu, submenu_index) in data.submenu"
                :key="`${index}-${submenu_index}`"
                :to="{ name: submenu.name, query: { t: new Date().getTime() } }"
                v-slot="{ href, navigate, isActive, isExactActive }"
              >
                <li
                  aria-haspopup="true"
                  data-menu-toggle="hover"
                  class="menu-item"
                  :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
                >
                  <a :href="href" class="menu-link" v-on:click="navigate">
                    <i class="menu-bullet menu-bullet-dot">
                      <span></span>
                    </i>
                    <span class="menu-text">{{ submenu.text }}</span>
                  </a>
                </li>
              </router-link>
            </ul>
          </div>
        </li>
      </template>
      <template v-else>
        <template v-if="true">
          <router-link
            :key="index"
            :to="{ name: data.name, query: { t: new Date().getTime() } }"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <span class="menu-svg-icon mr-4">
                  <!--begin::Svg Icon-->
                  <!-- <inline-svg :src="$assetURL(data.icon)" /> -->
                  <inline-svg fill="#fff" style="height: 25px; width: 25px" :src="data.icon" />
                  <!--end::Svg Icon-->
                </span>
                <span class="menu-text">{{ data.text }}</span>
              </a>
            </li>
          </router-link>
        </template>
      </template>
    </template>
  </ul>
</template>

<script>
import { isEmpty } from "lodash";
export default {
  name: "KTMenu",
  data() {
    return {
      menuList: [
        {
          name: "customer",
          text: "Customers",
          icon: "media/svg/logos/customer.svg",
          query: { t: new Date().getTime() },
          child: false,
        },
        {
          name: "products",
          text: "Product",
          icon: "media/svg/logos/customer.svg",
          query: { t: new Date().getTime() },
          child: true,
          submenu: [
            {
              name: "products",
              text: "Products",
            },
            // {
            //   name: "Add Adjustment",
            //   text: "Add Adjustment",
            // },
            {
              name: "Adjustments",
              text: "Adjustments",
            },
            {
              name: "Stock Count",
              text: "Stock Counts",
            },
            // {
            //   name: "item",
            //   text: "Items",
            // },
            
          ],
        },
        {
          name: "finance",
          text: "Finance",
          icon: "media/svg/logos/customer.svg",
          query: { t: new Date().getTime() },
          child: true,
          submenu: [
            {
              name: "purchase-order",
              text: "Purchase Order",
            },
            {
              name: "bills-listing",
              text: "Bills",
            },
            {
              name: "payment-made-listing",
              text: "Payment Made",
            },
          ],
        },

        // {
        //   name: "segment",
        //   text: "Segment",
        //   icon: "media/svg/logos/segment.svg",
        //   query: { t: new Date().getTime() },
        //   child: false,
        // },
        {
          name: "consumptionReport",
          text: "Consumption Report",
          icon: "media/svg/logos/template.svg",
          query: { t: new Date().getTime() },
          child: true,
          submenu: [
            {
              name: "consumptionReport",
              text: "Master Report",
            },
          ],
        },
        // {
        //   name: "broadcast",
        //   text: "Broadcast",
        //   icon:"media/svg/logos/brodcast.svg",
        //   query: { t: new Date().getTime() },
        //   child: false,
        // },

        // {
        //   name: "promotion",
        //   text: "Promotion",
        //   icon: "media/svg/logos/employee.svg",
        //   query: { t: new Date().getTime() },
        //   child: false,
        // },
        {
          name: "employee",
          text: "Staff",
          icon: "media/svg/logos/customer.svg",
          query: { t: new Date().getTime() },
          child: true,
          submenu: [
            {
              name: "employee",
              text: "Employee",
            },
            {
              name: "leave",
              text: "Leave",
            },
            {
              name: "advance",
              text: "Advance",
            },
          ],
        },

        {
          name: "template",
          text: "Marketing",
          icon: "media/svg/logos/template.svg",
          query: { t: new Date().getTime() },
          child: true,
          submenu: [
            {
              name: "template",
              text: "Template",
            },
            {
              name: "segment",
              text: "Segment",
            },
            {
              name: "broadcast",
              text: "Broadcast",
            },
            {
              name: "promotion",
              text: "Promotion",
            },
          ],
        },

        {
          name: "salessummary",
          text: "Sales Summary",
          icon: "media/svg/logos/employee.svg",
          query: { t: new Date().getTime() },
          child: false,
        },

        {
          name: "supplier",
          text: "Supplier",
          icon: "media/svg/logos/employee.svg",
          query: { t: new Date().getTime() },
          child: false,
        },
        {
          name: "salary",
          text: "Salary",
          icon: "media/svg/logos/employee.svg",
          query: { t: new Date().getTime() },
          child: false,
        },
        {
          name: "setting",
          text: "Settings",
          icon: "media/svg/logos/setting.svg",
          query: { tab: "profile", t: new Date().getTime() },
          child: false,
        },
        {
          name: "blast-notification",
          text: "Blast Notification",
          icon: "media/svg/logos/notification.svg",
          query: { t: new Date().getTime() },
          child: false,
        },
      ],
    };
  },
  methods: {
    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    },
    isRouteActive(match) {
      if (isEmpty(this.$route.meta) === false) {
        return this.$route.meta.menuActive === match;
      }
      return false;
    },
    isChildActive(row) {
      let isActive = false;
      for (let i = 0; i < row.length; i++) {
        isActive = this.isRouteActive(row[i].menu);
        if (isActive) {
          return isActive;
        }
      }
      return isActive;
    },
  },
};
</script>
