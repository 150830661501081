<template>
  <div class="d-flex flex-column flex-root" v-if="isAuthenticated">
    <!-- begin:: Header Mobile -->
    <KTHeaderMobile></KTHeaderMobile>
    <!-- end:: Header Mobile -->

    <Loader v-if="loaderEnabled" v-bind:logo="loaderLogo"></Loader>

    <!-- begin::Body -->
    <div class="d-flex flex-row flex-column-fluid page">
      <!-- begin:: Aside Left -->
      <!-- <KTAside></KTAside> -->
      <!-- end:: Aside Left -->
      <KTAside></KTAside>
      <KTHeader></KTHeader>
      <div id="kt_wrapper" class="d-flex flex-column flex-row-fluid wrapper">
        <!-- begin:: Header -->

        <!-- end:: Header -->

        <!-- begin:: Content -->
        <div id="kt_content" class="content d-flex flex-column flex-column-fluid">
          <!-- begin:: Content Head -->

          <!-- begin:: Content Body -->
          <div class="d-flex flex-column-fluid">
            <v-app>
              <template v-if="errors && errors.length > 0">
                <div class="btx-alert">
                  <template v-for="(error, index) in errors">
                    <!-- <v-alert
                      :key="index"
                      v-model="error.model"
                      transition="slide-y-transition"
                      dense
                      icon="mdi-alert-circle-outline"
                      dismissible
                      border="left"
                      type="error"
                    >
                      <span v-html="error.message"></span>
                    </v-alert> -->
                    <v-snackbar
                      :key="index"
                      v-model="error.model"
                      :timeout="2000"
                      right
                      top
                      color="red"
                    >
                      <span style="font-size: 15px;">{{ error.message }}</span>
                    </v-snackbar>
                  </template>
                </div>
              </template>
              <template v-if="messages && messages.length > 0">
                <div class="btx-alert">
                  <template v-for="(success, index) in messages">
                    <!-- <v-alert
                      :key="index"
                      v-model="success.model"
                      transition="slide-y-transition"
                      dense
                      dismissible
                      border="left"
                      type="success"
                    >
                      <span v-html="success.message"></span>
                    </v-alert> -->
                    <v-snackbar
                      :key="index"
                      v-model="success.model"
                      :timeout="2000"
                      transition="slide-y-transition"
                   
                      right
                      top
                      dense
                      dismissible
                      :border="left"
                      color="success"
                    >
                    <span style="font-size: 15px;">{{ success.message }}</span>
                    </v-snackbar>
                  </template>
                </div>
              </template>
              <v-container class="bg-white main-container pt-0" fluid>
                <transition name="fade-in-up">
                  <router-view />
                </transition>
              </v-container>
            </v-app>
          </div>
        </div>
      </div>
    </div>
    <KTScrollTop></KTScrollTop>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import KTAside from "@/view/layout/aside/Aside.vue";
import KTHeader from "@/view/layout/header/Header.vue";
import KTHeaderMobile from "@/view/layout/header/HeaderMobile.vue";
import HtmlClass from "@/core/services/htmlclass.service";
import KTScrollTop from "@/view/layout/extras/ScrollTop";
import Loader from "@/view/content/Loader.vue";
import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME,
} from "@/core/services/store/htmlclass.module.js";

export default {
  name: "Layout",
  components: {
    KTAside,
    KTHeader,
    KTHeaderMobile,
    KTScrollTop,
    Loader,
  },
  beforeMount() {
    // show page loading
    this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");

    // initialize html element classes
    HtmlClass.init(this.layoutConfig());
  },
  mounted() {
    // check if current user is authenticated
    if (!this.isAuthenticated) {
      this.$router.push({ name: "login" });
    }

    // Simulate the delay page loading
    setTimeout(() => {
      // Remove page loader after some time
      this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
    }, 2000);
  },
  methods: {},
  computed: {
    ...mapGetters(["isAuthenticated", "errors", "messages", "layoutConfig"]),

    /**
     * Check if the page loader is enabled
     * @returns {boolean}
     */
    loaderEnabled() {
      return !/false/.test(this.layoutConfig("loader.type"));
    },

    /**
     * Check if container width is fluid
     * @returns {boolean}
     */
    contentFluid() {
      return this.layoutConfig("content.width") === "fluid";
    },

    /**
     * Page loader logo image using require() function
     * @returns {string}
     */
    loaderLogo() {
      return process.env.BASE_URL + this.layoutConfig("loader.logo");
    },

    /**
     * Check if the left aside menu is enabled
     * @returns {boolean}
     */
    asideEnabled() {
      return !!this.layoutConfig("aside.self.display");
    },
  },
};
</script>

<style>
div#kt_content {
  padding: 0px;
}
</style>
