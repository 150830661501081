<template>
  <!-- begin:: Header -->
  <div id="kt_header" ref="kt_header" class="header" v-bind:class="headerClasses">
    <div
      class="container-fluid d-flex align-items-center justify-content-between"
      style="z-index: 100"
    >
      <!-- begin:: Header Menu -->
      <v-layout class="header-menu-wrapper header-menu-wrapper-left">
        <v-flex md1 style="width: 200px; min-width: 185px" class="my-auto brand-logo">
          <img :src="$assetURL('media/logos/logo-tt.png')" alt="brand-logo" />
        </v-flex>
        <!-- 
        <v-flex md5 class="my-auto header-menu header-menu-mobile">
          <ul class="menu-nav">
          <li></li>
            <router-link
              :to="{ name: 'customer', query: { t: new Date().getTime() } }"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <span class="menu-text"> Customers </span>
                </a>
              </li>
            </router-link>
            <router-link
              :to="{ name: 'item', query: { t: new Date().getTime() } }"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <span class="menu-text"> Items </span>
                </a>
              </li>
            </router-link>
              <router-link
              :to="{ name: 'promotion', query: { t: new Date().getTime() } }"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <span class="menu-text"> Promotions </span>
                </a>
              </li>
            </router-link>
            <router-link
              :to="{ name: 'setting', query: { t: new Date().getTime(), tab: 'company' } }"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <span class="menu-text"> Settings </span>
                </a>
              </li>
            </router-link>
            <router-link
              :to="{ name: 'pos-table', query: { t: new Date().getTime() } }"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <span class="menu-text"> POS </span>
                </a>
              </li>
            </router-link>
            <li class="menu-item menu-item-active1">
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <li v-bind="attrs" v-on="on" aria-haspopup="true" data-menu-toggle="hover">
                    <a class="menu-link">
                      <span class="menu-text"> Marketing </span>
                    </a>
                  </li>
                </template>
                <v-list class="d-block">
                  <router-link
                    :to="{ name: 'broadcast', query: { t: new Date().getTime() } }"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                  >
                    <v-list-item
                      :class="[
                        isActive && 'menu--list-active',
                        isExactActive && 'menu--list-active',
                      ]"
                      link
                      class="d-block"
                    >
                      <a :href="href" class="menu-link" @click="navigate">
                        <v-list-item-title class="py-2">Broadcast </v-list-item-title>
                      </a>
                    </v-list-item>
                  </router-link>

                  <router-link
                    :to="{ name: 'segment', query: { t: new Date().getTime() } }"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                  >
                    <v-list-item
                      :class="[
                        isActive && 'menu--list-active',
                        isExactActive && 'menu--list-active',
                      ]"
                      link
                      class="d-block"
                    >
                      <a :href="href" class="menu-link" @click="navigate">
                        <v-list-item-title class="py-2">Segment</v-list-item-title>
                      </a>
                    </v-list-item>
                  </router-link>
                  <router-link
                    :to="{ name: 'template', query: { t: new Date().getTime() } }"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                  >
                    <v-list-item
                      :class="[
                        isActive && 'menu--list-active',
                        isExactActive && 'menu--list-active',
                      ]"
                      link
                      class="d-block"
                    >
                      <a :href="href" class="menu-link" @click="navigate">
                        <v-list-item-title class="py-2">Template</v-list-item-title>
                      </a>
                    </v-list-item>
                  </router-link>
                </v-list>
              </v-menu>
            </li> 
          </ul>
        </v-flex> -->
        <v-flex md6 class="my-auto">
          <KTTopSearchbar v-if="searchEnabled"></KTTopSearchbar>
        </v-flex>
      </v-layout>
      <!-- end:: Header Menu -->
      <KTTopbar></KTTopbar>
    </div>
  </div>
  <!-- end:: Header -->
</template>

<script>
import { mapGetters } from "vuex";
import KTTopbar from "@/view/layout/header/Topbar.vue";
import KTTopSearchbar from "@/view/layout/header/TopSearchbar.vue";
import KTLayoutHeader from "@/assets/js/layout/base/header.js";
import KTLayoutHeaderMenu from "@/assets/js/layout/base/header-menu.js";

export default {
  name: "KTHeader",
  components: {
    KTTopbar,
    KTTopSearchbar,
  },
  mounted() {
    // Init Desktop & Mobile Headers
    KTLayoutHeader.init("kt_header", "kt_header_mobile");

    // Init Header Menu
    KTLayoutHeaderMenu.init(this.$refs["kt_header_menu"], this.$refs["kt_header_menu_wrapper"]);

    /*const headerRef = this.$refs["kt_header"];

    headerRef.querySelectorAll("a[class='menu-link']").forEach(item => {
      item.addEventListener("click", () => {
        KTLayoutHeaderMenu.getOffcanvas().hide();
      });
    });*/
  },
  computed: {
    ...mapGetters(["layoutConfig", "getClasses", "breadcrumbs", "pageTitle", "searchEnabled"]),

    /**
     * Check if the header menu is enabled
     * @returns {boolean}
     */
    headerMenuEnabled() {
      return !!this.layoutConfig("header.menu.self.display");
    },

    /**
     * Get extra classes for header based on the options
     * @returns {null|*}
     */
    headerClasses() {
      const classes = this.getClasses("header");
      if (typeof classes !== "undefined") {
        return classes.join(" ");
      }
      return null;
    },

    /**
     * Get extra classes for header menu based on the options
     * @returns {null|*}
     */
    headerMenuClasses() {
      const classes = this.getClasses("header_menu");
      if (typeof classes !== "undefined") {
        return classes.join(" ");
      }
      return null;
    },
  },
};
</script>
