<template>
  <div class="topbar-item">
    <div
      class="btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2"
      id="kt_quick_user_toggle"
    >
      <span class="text-muted font-weight-bold font-size-base d-none d-md-inline mr-1">
        Hi, {{
      }}</span>
      <span class="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3">
        <template v-if="currentUser && currentUser?.display_name">
          {{ currentUser?.display_name }}
        </template>
      </span>
      <span class="symbol symbol-35 symbol-light-success">
        <span class="symbol-label font-size-h5 font-weight-bold">
          <template v-if="currentUser && currentUser?.first_name.charAt(0).toUpperCase()">
            {{ currentUser?.first_name.charAt(0).toUpperCase() }}
          </template>
        </span>
      </span>
    </div>

    <div id="kt_quick_user" ref="kt_quick_user" class="offcanvas offcanvas-right p-10">
      <!--begin::Header-->
      <div class="offcanvas-header d-flex align-items-center justify-content-between pb-5">
        <h3 class="font-weight-bold m-0">User Profile</h3>
        <a
          href="#"
          class="btn btn-xs btn-icon btn-light btn-hover-primary"
          id="kt_quick_user_close"
        >
          <i class="ki ki-close icon-xs text-muted"></i>
        </a>
      </div>
      <!--end::Header-->

      <!--begin::Content-->
      <perfect-scrollbar
        class="offcanvas-content pr-5 mr-n5 scroll"
        style="max-height: 85vh; position: relative"
      >
        <!--begin::Header-->
        <div class="d-flex align-items-center mt-5">
          <div class="symbol symbol-100 mr-5">
            <img class="symbol-label" :src="$assetURL('media/users/blank.png')" alt="" />
          </div>
          <div class="d-flex flex-column">
            <p class="m-0 font-weight-bold font-size-h5 text-dark-75 text-hover-primary">
              {{ getFullName }}
            </p>
            <div class="navi mt-2">
              <a :href="`mailto:${currentUser?.email}`" class="navi-item">
                <span class="navi-link p-0 pb-2">
                  <span class="navi-icon mr-1">
                    <span class="svg-icon svg-icon-lg svg-icon-primary">
                      <!--begin::Svg Icon-->
                      <inline-svg
                        :src="$assetURL('media/svg/icons/Communication/Mail-notification.svg')"
                      />
                      <!--end::Svg Icon-->
                    </span>
                  </span>
                  <span class="navi-text text-muted text-hover-primary">
                    {{ currentUser?.email }}
                  </span>
                </span>
              </a>
            </div>
            <button class="btn btn-light-primary btn-bold" @click="onLogout">Sign out</button>
          </div>
        </div>
        <!--end::Header-->
        <!-----------------start::Users----------------->
        <p style="border-bottom: 1px dotted rgb(211, 208, 208)" class="mt-3"></p>
        <div>
          <router-link :to="{ name: 'users', query: { tab: 'profile', t: new Date().getTime() } }">
            <div class="d-flex p-1">
              <div>
                <v-icon
                  color="#3699FF"
                  style="
                    background-color: rgb(241 239 239);
                    border-radius: 4px;
                    height: 50px;
                    width: 50px;
                  "
                  class="pb-0"
                  left
                  >person</v-icon
                >
              </div>
              <div>
                <p class="m-0 font-weight-bold font-size-h5 text-dark-75 text-hover-primary">
                  Users
                </p>
                <p class="m-0 text-dark-75">Manage all users</p>
              </div>
            </div>
          </router-link>
        </div>
        <!-----------------end::Users----------------->

        <!-----------------start::Settings----------------->
        <p style="border-bottom: 1px dotted rgb(211, 208, 208)" class="mt-3"></p>
        <div class="setting_tab">
          <router-link
            :to="{ name: 'setting', query: { tab: 'profile', t: new Date().getTime() } }"
          >
            <div class="d-flex p-1">
              <div>
                <v-icon
                  color="#3699FF"
                  style="
                    background-color: rgb(241 239 239);
                    border-radius: 4px;
                    height: 50px;
                    width: 50px;
                  "
                  class="pb-0"
                  left
                  >settings</v-icon
                >
              </div>
              <div>
                <p class="m-0 font-weight-bold font-size-h5 text-dark-75 text-hover-primary">
                  Settings
                </p>
                <p class="d-flex m-0 text-dark-75">
                  Account settings and more
                  <v-chip hide-details class="ma-2" color="pink" label text-color="white" small>
                    update
                  </v-chip>
                </p>
              </div>
            </div>
          </router-link>
        </div>
        <!-----------------end::Settings----------------->
      </perfect-scrollbar>
    </div>
  </div>
</template>

<style lang="scss" scoped>
#kt_quick_user {
  overflow: hidden;
}
</style>

<script>
import { mapGetters } from "vuex";
import { LOGOUT } from "@/core/services/store/auth.module";
import KTLayoutQuickUser from "@/assets/js/layout/extended/quick-user.js";
import KTOffcanvas from "@/assets/js/components/offcanvas.js";

export default {
  name: "KTQuickUser",
  mounted() {
    // Init Quick User Panel
    KTLayoutQuickUser.init(this.$refs["kt_quick_user"]);
  },
  methods: {
    onLogout() {
      this.$store
        .dispatch(LOGOUT)
        .then(() => {
          this.deleteAllCookies();
          setTimeout(function () {
            window.location.reload();
          }, 500);
        })
        .catch(() => {
          window.location.reload();
        });
    },
    closeOffcanvas() {
      new KTOffcanvas(KTLayoutQuickUser.getElement()).hide();
    },
    deleteAllCookies() {
      let cookies = document.cookie.split(";");
      for (let i = 0; i < cookies.length; i++) {
        let cookie = cookies[i];
        let eqPos = cookie.indexOf("=");
        let name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
      }
    },
    // routeToDetail(id) {
    //   this.$router.push({
    //     name: "customer-detail",
    //     params: { id },
    //     query: { t: new Date().getTime() },
    //   });
    // },
  },
  computed: {
    ...mapGetters(["currentUser"]),

    getFullName() {
      return this.currentUser?.first_name + " " + (this.currentUser?.last_name || "");
    },
  },
};
</script>
